<template>
  <div class="main-page-bottom app-layout-bottom">
    <div class="main-page-bottom-text">存款帮助</div>
    <div class="main-page-divider"></div>
    <div class="main-page-bottom-text">联系我们</div>
    <div class="main-page-divider"></div>
    <div class="main-page-bottom-text">取款帮助</div>
    <div class="main-page-divider"></div>
    <div class="main-page-bottom-text">常见问题</div>
  </div>
</template>

<script>
export default {
  name: "AppBtmBar",
};
</script>
